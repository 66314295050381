/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


/* Container for table widgets (filter, count, page length) */
.dataTables_wrapper .top {
	border-bottom: 0.1rem solid #003049;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	font-size: 0.9em;
}

/* Panel-body used a lot as the table container, default is 1.5rem so give more space when viewport is small */
@media (max-width: 500px) {
	.panel-body {
		padding: 0.5rem;
	}
}

/* Filter search input */
.dataTables_filter input[type='search'] {
	max-width: 100% !important;
	border-radius: var(--rounded-corners);
	padding-left: 1rem;
	font-weight: normal;
}

/* MOBILE  */
@media (max-width: 700px) {
	.dataTables_filter > label {
		display: block; /* makes filter input full width (?) */
	}

	.dataTables_filter input[type='search'] {
		width: 100%;
		margin-left: 0;
	}

	/* TODO: figure out how to make some columns smaller for mobile (e.g. service tab on mobile, name should be a lot smaller than 50%  */
}

/* DESKTOP - general: spread things out more */
@media (min-width: 700px) {
	/* Search bar - take up entire row, push other stuff to next row */
	.dataTables_filter {
		width: 100% !important;
	}
	/* Search bar - make text box take up entire row */
	.dataTables_filter input[type='search'] {
		flex-grow: 1;
	}

	/* "Filter items" label */
	.dataTables_filter > label {
		display: flex;
		width: 100%;
	}

	/* Info and page length */
	.dataTables_info,
	.dataTables_length {
		margin-top: 0.3rem;
	}

	/* #searchOffices_info::after { */
	.dataTables_info::after {
		content: '' !important;
	}

	/* Page length display - float right*/
	.dataTables_length {
		float: right;
	}
}

/* COLUMN VARIATIONS - display different data based on display size */
@media (max-width: 700px) {
	/* Used when you have 2 versions of something depending on viewport */
	.display-full { display: none; }
	.display-short { display: contents; }

	/* Shrink sort arrows */
	.sorting-icons {
		display: block !important; /* put arrows on their own line to save width */
		font-size: 1rem;
	}
}

@media (min-width: 700px) {
	/* Used when you have 2 versions of something depending on viewport */
	.display-full { display: contents; }
	.display-short { display: none; }
}
