/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


.edit-button {
	padding-left: 1rem;
}

/* Container for district, expired, wiki profile */
#top-office-row {
	margin: 0;
	display: flex;
	align-items: center;
}

#wiki-profile {
	margin-left: auto;
}

#region-district {
	margin-top: 1rem;
}

/* Internal-only */
#address-edit-button {
	width: 2rem;
	padding-left: 0;
}

#addresses {
	margin: 0 0 1rem 0;
}

.address-listing {
	font-weight: 500;
}

.directions {
	border: none;
	background: none;
	vertical-align: middle;
	margin-left: 1rem;
}

.note-div {
	margin-bottom: 0.7em;
}

#officeTable th {
	background-color: var(--color-std-dark);
	color: white;
	text-align: center;
}

/* Primarily for long emails on small screens so they won't overflow */
.break-word {
	word-wrap: break-word;
}

/* Category headers less padding on mobile */
@media (min-width: 900px) {
  #officeTable th {
    /* padding: 0.5rem 0.5rem 0 0.5rem; */
	padding-left: 5rem;
	padding-right: 5rem;
  }
}

/* Colour edit buttons white so can be seen on blue background */
#officeTable th .fa-pen {
	color: white;
}

/* Internal-only - admin */
#expired {
	color: var(--color-std-red);
	margin-left: 2rem;
	padding: 0.5rem;
	font-size: 1.7rem;
}

#airport-label {
	font-size: 1.1em;
}

/* Designations (customs, POE) */
.designations {
	padding-left: 1.3rem;
}

.designation-entry {
	display: grid;
	grid-template-columns: 1fr 15fr;
	padding-left: 0;
}

.designations .fa-solid {
	align-content: center;
}

.designation-label {
	margin-left: 1rem;
}

/* Internal-only - STANDARD SECTION  */
.standard-office-container:nth-child(2) {
	margin-top: 1.5rem;
}

.standard-office-info {
	margin-left: 1rem;
}

.wloc-type {
	margin-left: 1em;
}

.variant-container {
	margin: 0.7rem 0 1rem 0;
}

.variant-box {
	font-size: 75%;
	font-weight: 700;
	padding: 0.5rem 1rem;
	border: 0.1rem solid #083c6c;
	border-radius: var(--pill-corners);
	background-color: #e8f2f4;
	margin-right: 0.8rem;
	margin-bottom: 0.8rem;

	/* position: relative; */
	display: inline-block;
	white-space: nowrap;
}

.standard-office-wrapper {
	transition: opacity 500ms ease-out;
	opacity: 0;
}

.subloc-parent-container {
	padding: 0.5rem 1rem;
	border-radius: var(--pill-corners);
	margin-right: 0.8rem;
}

.reports-to-label {
	font-weight: bold;
}

.subloc-label-button {
	border: none;
	background: none;
	padding-inline: 0;
	font-weight: bold;
}

.subloc-collapsible {
	padding-top: 0.2rem;
	margin-left: 1.5rem;
	/* float: right; */
	transition: transform 0.3s ease;
}
/* Change chevron for expand/collapse sub-locs */
.flip {
	transform: rotate(90deg);
	margin-bottom: 1rem;
}

.subloc-child-container {
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.5s ease-out;
}

/* OFFICE TABLE */

/* Office table content sections */
.table-content {
	padding: 1rem 1.5rem !important;
}

/* External-only */
.contact-info {
	padding-bottom: 0.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 0.1rem solid #ddd;
}

.row-entries {
	margin: 0.5rem 0;
}

/* TODO: this is not a great fix, need a way to ensure box won't go off screen */
@media (max-width: 699px) {
	.custom-tooltip:hover::after {
		max-width: 60vw;
		right: 0;
	}
}

@media (min-width: 700px) {
	.custom-tooltip:hover::after {
		max-width: 40vw;
		left: 0;
	}
}

/* Entire services box */
.services-container {
	padding-top: 2rem !important;
	padding-left: 0 !important;
}

/* Category-services row */
.service-cat-container {
	padding-bottom: 2rem;
}

.category-container {
	margin-bottom: 0.5rem;
	font-weight: bold;
}

.category-container i {
	width: 4rem;;
}

.service-aoe-info {
	margin-left: 1.5rem;
}

.service-icon:before {
    border: 0.1rem solid var(--color-std-dark);
    padding: 0.5rem;
    border-radius: var(--rounded-corners);
    margin-right: 0.8rem;
}

#aoe-child {
	/* margin: 1rem 0 0 1rem; */
	padding: 0.5rem 1rem;
}

/* HOURS */
.hours-container {
	border: none !important;
	display: flex;
	flex-wrap: wrap;
}

.hours {
	margin: 0.5rem;
	padding: 1rem;
	background-color: #e8f2f4;
	border: 0.1rem solid #ddd;
}

@media (min-width: 900px) {
	.hours {
		width: calc(50% - 1rem);
	}
}

/* Mobile - change hours to only 1 box per row */
@media (max-width: 899px) {
	.hours {
		width: 100%;
	}
}

.hours-label {
	font-weight: bold;
}

.hours-description {
	font-size: 1.5rem;
	padding: 0.5rem 0 1rem;
}

.hours-days-container {
	font-size: 1.5rem;
	white-space: pre-wrap;
}

.hours-day {
	margin-bottom: 1rem;
}

.hours-day,
.listing-container {
	display: flex;
	flex-wrap: wrap;
}

.listing-label {
	padding-left: 0;
}

/* Internal-only - Hour-edit */
.edit-hour {
	border: 0.3rem dashed var(--color-std-dark);
}

#notes-errors {
	font-size: 0.9em;
}

#last-update {
	position: fixed;
	font-style: italic;
	font-size: 0.9em;
	bottom: 1rem;
	right: 2.5rem;
	z-index: 999;
	border-radius: var(--rounded-corners);
	background-color: white;
	padding: 0.5rem;
}

@media (min-width: 700px) {
	#office-map-holder {
		margin-bottom: 1.5rem;
	}
}

@media (max-width: 699px) {
	#office-map-holder {
		margin-bottom: 1rem;
	}
}

/* MISC */

.show-opacity {
	opacity: 1 !important;
}
