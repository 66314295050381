/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


main {
    font-size: 1.6rem;
}

#welcome-user {
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 1.9rem;
}

.welcome {
    font-weight: bold;
    margin-bottom: 1.5rem;
}

/* TABS */

#tab-info {
    margin: 2.5rem;
}

.tab-button-holder {
    position: relative;
    overflow: hidden;
}

/* Tab loading spinner on mobile look nicer */
@media (max-width: 700px) {
    .tab-spinner {
        margin: 1.5rem 0 1rem 0;
    }
}

#tab-set {
    display: flex;
    justify-content: center;
    width: 100%;
}

/* Style the buttons inside the tab */
.tab-button-holder button {
    flex: 1 1 0;
    background: var(--color-std-light-blue);
    border: 0.1rem solid var(--color-std-light);
    display: table-cell;
    position: relative;
    max-width: 20%;
    margin: 0 0.5rem; 
    min-height: 3em;
    border-bottom: 0;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    /* padding: 1rem 0; */
    z-index: 1;
}

/* Tab-buttons - mobile */
@media (max-width: 700px) {
    .tab-button-holder button {
        padding: 0.5rem 0;
        width: auto;
        max-width: none;

        /* Following puts the icon on its own line */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

/* Info-tab - desktop - separately left justified */
@media (min-width: 700px) {
    #info-tab {
        position: absolute;
        left: 1.5rem;
        width: 5rem;
        bottom: 0;
    }
}

/* Info-tab - mobile - minimum size to make more room for other tabs */
@media (max-width: 700px) {
    #info-tab {
        flex: none;
        width: auto;
        padding: 0 1rem;
    }

    /* non-home icons */
    .browse-icon { 
        padding: 1.5rem 0;
    }
}

/* Tabs appearance while loading */
.tab-button-holder button:disabled {
    color: gray;
    opacity: 0.8;
}

/* Tab - space after icon */
@media (min-width: 700px) {
    .tab-button-holder button:not(#info-tab) i { /* don't apply to home/info icon */
        margin-right: 1rem;
    }
}

/* Change background color of buttons on hover */
.tab-button-holder button:not(.active-tab-button):hover {
    background-color: var(--color-std-light);
    /* box-shadow: 0 0.5rem 0.8rem gray; */
}

.tab-button-holder .active-tab-button {
    background-color: white !important;
    z-index: 2;
    border-top: 0.4rem solid #003049 !important;
    box-shadow: 0 0.5rem 0.8rem gray;
}

#tab-body {
    min-height: 50rem;
    border-radius: var(--rounded-corners);
    border: 0.1rem solid var(--color-std-light);
    padding: 0.6rem 1.2rem;
    top: -0.1rem;
    position: relative;
    z-index: 1;
    transition: height 500ms ease;
    box-shadow: 0 0.8rem 1rem 0.1rem rgba(0,0,0,.14), 0 0.3rem 1.4rem 0.2rem rgba(0,0,0,.12),0 0.5rem 0.5rem -0.3rem rgba(0,0,0,.2);
}

.tab-body {
    display: none;
    animation-duration: 225ms;
}

.active-body {
    display: block;
}

/* REGION / PROVINCES TAB */

#region-container > .region-button {
    font-weight: bold;
    vertical-align: top;
    border: none;
    background-color: var(--color-std-light-blue);
}

#region-container > .region-button:hover {
    background-color: var(--color-std-light);
}

.active-region {
    border-top: 0.3rem solid #003049 !important;
    background-color: white !important;
}

.regions {
    margin: 1.5rem;
}

.region-select {
    margin-top: 2rem;
}

.region-title {
    text-align: center;
    margin-bottom: 2.5rem;
}

.region div {
    text-align: center;
}

.district {
    font-size: 0.9em;
    margin: 1rem 0;
    text-align: left !important;
}

/* TABS */

#find-office {
    margin: 2rem 0;
    text-align: center;
}

#quick-nav {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.search-element:hover {
    transform: scale(1.05);
}

#search-button {
    background-color: #276cb9;
    width: 10rem;
    text-align: center;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0 0.5rem 1.5rem;
    color: #ffffff;
    border: none;
    cursor: pointer;
}