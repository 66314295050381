/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


.error-container {
	position: absolute;
	z-index: 9999;
	left: 0;
	top: 0;
	min-width: 100%;
	min-height: 100%;
	opacity: 0;
	transition: opacity 500ms ease;
	visibility: hidden;
	background-color: white;
}

.modal-body {
    /*overflow: hidden !important;  Removes the ability to scroll down the window */
}

.message {
    padding: 20px;
}

.show {
	opacity: 1; /* Show the error with fade-in effect */
	visibility: visible;
	transition: opacity 500ms ease;
}