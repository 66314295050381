/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


/* Top margin between table and map */

@media (max-width: 700px) {
	.data-table-wrapper {
		margin-top: 1.5rem;
	}
}

@media (min-width: 699px) {
	.data-table-wrapper {
		margin-top: 2rem;
	}
}

/* COLUMN VARIATIONS - display different data based on display size */
@media (max-width: 700px) {
	/* Used when you have 2 versions of something depending on viewport */
	.display-full { display: none; }
	.display-short { display: contents; }

	/* Shrink sort arrows */
	.sorting-icons {
		display: block !important; /* put arrows on their own line to save width */
		font-size: 1rem;
	}
}

@media (min-width: 700px) {
	/* Used when you have 2 versions of something depending on viewport */
	.display-full { display: contents; }
	.display-short { display: none; }
}

.geo-alias {
	font-style: italic;
	font-size: 1.4rem;
	margin-left: 2rem;
	opacity: 0.6;
}

.chip-container {
    margin: 1rem;
}

.chip-filter {
	font-size: 0.9em;
	font-weight: 700;
	padding: 0.3rem 0.7rem;
	border: 0.1rem solid #083c6c;
	border-radius: var(--pill-corners);
	background-color: #e8f2f4;
	margin-right: 0.8rem;
    margin-bottom: 1rem;
}

/* X button for chip */
.chip-filter span {
    margin-left: 1rem;
}

/* Bold the first option (title) of the filters */
.region-filter-select option:first-child {
    font-weight: bold;
}

.dataTables_info::after {
	content: '' !important;
}

